import { useQuery } from "react-query";
import { IJourney } from "../_interfaces/journey.interface";
import { getGuestsOfJourneyById, getJourneyById, getSessionsOfJourneyById } from "../_services/journey.service";
import { IGuest } from "../_interfaces/guest.interface";
import { ISession } from "../_interfaces/session.interface";

export const useJourneyData = (id: string) => {
  return useQuery<IJourney, Error>("journey-query", () => getJourneyById(id));
};

export const useGuestsOfJourneyData = (id: string) => {
  return useQuery<IGuest[], Error>("journey-guests-query", () => getGuestsOfJourneyById(id), { initialData: [] });
};

export const useSessionsOfJourneyData = (id: string) => {
  return useQuery<ISession[], Error>("journey-sessions-query", () => getSessionsOfJourneyById(id), { initialData: [] });
};
