import * as React from 'react';
import { IJourney } from '../../_interfaces/journey.interface';
import SurfIcon from '@mui/icons-material/Surfing';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Badge from '@mui/material/Badge';
import PersonIcon from '@mui/icons-material/Person';
import List from '@mui/material/List';
import EditIcon from '@mui/icons-material/Edit';
import { useRecoilValue } from 'recoil';
// import { guestListState } from '../../_states/guests.state';
import { sessionListState } from '../../_states/sessions.state';
import GuestsListItem from '../Guests/GuestListItem';
import SessionListItem from '../Sessions/SessionListItem';
import { useNavigate } from 'react-router-dom';
import { Alert } from '@mui/material';
import BoatHeader from '../Boat/BoatHeader';
import SessionListCollapse from '../Sessions/SessionListCollapse';

interface IJourneySummaryProps {
  journey: IJourney | null;
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function JourneySummary(props: IJourneySummaryProps) {
  const { journey } = props;
  const navigate = useNavigate();
  const [guestExpanded, setGuestExpanded] = React.useState(false);
  const [sessionExpanded, setSessionExpanded] = React.useState(false);
  // const guests = useRecoilValue(guestListState);
  // const sessions = useRecoilValue(sessionListState);

  const handleGuestExpandClick = () => {
    setGuestExpanded(!guestExpanded);
  };
  const handleSessionExpand = () => {
    setSessionExpanded(!sessionExpanded);
  };

  const handleEditClicked = () => {
    navigate(`/journey-detail/${journey?.id}`);
  };

  return (
    <>
      <Card sx={{ margin: 1 }}>
        {journey ? (
          <>
            <BoatHeader
              journey={journey}
              action={
                <IconButton aria-label="settings" onClick={handleEditClicked}>
                  <EditIcon />
                </IconButton>
              }
            />
            <CardActions sx={{ marginTop: 2, paddingLeft: 3, minHeight: 50 }}>
              <Badge badgeContent={journey.guests.length} color="secondary" showZero>
                <PersonIcon color="action" />
              </Badge>
              <Typography sx={{ marginLeft: 3 }}>Gäste</Typography>
              {journey.guests.length > 0 ? (
                <ExpandMore
                  expand={guestExpanded}
                  onClick={handleGuestExpandClick}
                  aria-expanded={guestExpanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              ) : null}
            </CardActions>
            <Collapse in={guestExpanded} timeout="auto" unmountOnExit>
              <CardContent sx={{ pt: 0 }}>
                <List sx={{ pt: 0 }}>
                  {journey.guests.map((guest, index) => (
                    <GuestsListItem guest={guest} />
                  ))}
                </List>
              </CardContent>
            </Collapse>
            <SessionListCollapse sessions={journey.sessions} />
          </>
        ) : (
          <Alert severity="info">Keine laufende Fahrt aktiv</Alert>
        )}
      </Card>
    </>
  );
}
